<template>
  <b-form>
    <b-card>
      <b-card-text>
        <b-row>
          <b-col>
            <h5>Talep Eden</h5>
            <p>{{ purchasing_request.username }}</p>
          </b-col>
          <b-col>
            <h5>Marka</h5>
            <p>{{ purchasing_request.brand }}</p>
          </b-col>
          <b-col>
            <h5>Departman</h5>
            <p>{{ purchasing_request.department }}</p>
          </b-col>
          <b-col>
            <h5>Oluşturma Tarihi</h5>
            <p>{{ moment(purchasing_request.created).format('DD.MM.YYYY HH:mm') }}</p>
          </b-col>
          <b-col md="12">
            <h5>Notlar</h5>
            <p>{{ purchasing_request.notes }}</p>
          </b-col>
          <b-col md="12">
            <h5>Talepler</h5>
            <Request_lines />
          </b-col>
          <b-col
            v-if="purchasing_request.image"
            md="12"
          >
            <h5>Görsel</h5>
            <img
              :src="'media/purchasing/' + purchasing_request.image"
              alt=""
            >
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col
            sm="12"
            md="6"
          ><h6 class="mb-1">
            Sipariş Oluştur
          </h6></b-col>
        </b-row>
      </b-card-text>
      <b-row
        v-for="(item, index) in purchasing_request.lines"
        :id="item.id"
        :key="item.id"
        ref="row"
      >
        <b-col md="4">
          <b-form-group
            label="Ürün Grubu"
          >
            <v-select
              v-model="item.id_com_product_groups"
              :options="productGroups"
              placeholder="Seçiniz"
              :reduce="productGroup => productGroup.id"
            >
              <template v-slot:option="option">
                {{ option.title }}
                <div>
                  <small class="text-danger">{{ option.product_type }}</small>
                </div>
              </template>
              <template v-slot:selected-option="option">
                {{ option.title }}&nbsp;
                <div class="text-danger">
                  <small> {{ option.product_type }}</small>
                </div>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Açıklama"
          >
            <b-form-input
              v-model="item.content"
              placeholder="Açıklama"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Miktar"
          >
            <b-form-input
              v-model="item.quantity"
              placeholder="Miktar"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Ünite"
          >
            <v-select
              v-model="item.id_com_product_units"
              :options="productUnits"
              :reduce="productUnit => productUnit.id"
              label="title"
              placeholder="Seçiniz"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Tedarikçi"
          >
            <v-select
              v-model="item.id_com_suppliers"
              :options="suppliers"
              :reduce="supplier => supplier.id"
              label="title"
              placeholder="Seçiniz"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Fiyat"
          >
            <b-form-input
              v-model="item.price"
              placeholder="Fiyat"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="12"
          block
          class="mb-50"
        >
          <b-button
            variant="outline-danger"
            class="mt-0 mt-md-2"
            @click="removeItem(index)"
          >
            <feather-icon
              icon="XIcon"
              class="mr-25"
            />
            <span>Sil</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </b-form>
</template>

<script>
import {
  BForm,
  BCard,
  BCardText,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
// import Request_lines from '@/views/Purchasing/components/Request_lines.vue'

export default {
  name: 'Add',
  components: {
    BForm,
    BCard,
    BCardText,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    vSelect,
    // Request_lines,
  },
  mixins: [heightTransition],
  data() {
    return {
      submitStatus: false,
      formData: {
        price: null,
        id_com_purchasing_requests: null,
        id_com_purchasing_statuses: null,
        id_com_suppliers: null,
        id_currency: null,
      },
      options: {
        currencies: [
          {
            id: 1,
            title: 'TL',
          },
          {
            id: 2,
            title: 'Dolar',
          },
          {
            id: 3,
            title: 'Euro',
          },
        ],
      },
    }
  },
  computed: {
    purchasing_request() {
      return this.$store.getters['purchasingRequests/getPurchasingRequest']
    },
    suppliers() {
      return this.$store.getters['suppliers/getSuppliers']
    },
    statuses() {
      return this.$store.getters['purchasingStatuses/getPurchasingStatuses']
    },
    productGroups() {
      return this.$store.getters['productGroups/getProductGroups']
    },
    productUnits() {
      return this.$store.getters['productUnits/getProductUnits']
    },
    saveStatus() {
      return this.$store.getters['purchasingRequests/getPurchasingRequestSave']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.formData = {
          price: null,
          id_com_purchasing_requests: null,
          id_com_purchasing_statuses: null,
          id_com_suppliers: null,
          id_currency: null,
        }
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'Kayıt işleminiz başarılı!',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Kayıt işleminiz yapılamadı.! Lütfen tekrar deneyiniz.',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getData()
    this.getSuppliers()
    this.getStatuses()
    this.getProductUnits()
    this.getProductGroups()
  },
  methods: {
    getData() {
      this.$store.dispatch('purchasingRequests/purchasingRequestView', this.$route.params.id)
    },
    getSuppliers() {
      this.$store.dispatch('suppliers/suppliers')
    },
    getStatuses() {
      this.$store.dispatch('purchasingStatuses/purchasingStatuses')
    },
    getProductGroups() {
      this.$store.dispatch('productGroups/productGroups')
    },
    getProductUnits() {
      this.$store.dispatch('productUnits/productUnits')
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.submitStatus = true
      this.$store.dispatch('purchasingOrders/purchasingOrdersSave', this.formData)
    },
    repeateAgain() {
      this.purchasing_request.lines.push({
        id_com_product_groups: null,
        id_com_product_units: null,
        content: null,
        quantity: null,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.purchasing_request.lines.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>
